const tags = [
  {
    id: '1',
    type: 'price',
    position: { x: -5.407626584829071, y: 0.6381990542953387, z: 7.56129780223141 },
    face: { x: -0.026806940485731387, y: 0.9988548554924176, z: 0.03962783871240152 },
    shape: {
      width: 3,
      height: 2,
      borderRadius: 0.08,
    },
    seriesName: 'WATTLEBIRD',
    name: 'VANILLA',
    description: 'Admire the sun-soaked wattle and bursts of wildflowers',
    productId: 'Wat-Van',
  },
  {
    id: '2',
    type: 'price',
    position: { x: -14.527564378701861, y: 0.5549596002986157, z: 7.695691335028005 },
    face: { x: -0.026371592909223637, y: 0.9918650039503726, z: 0.12453253802102376 },
    shape: {
      width: 3,
      height: 2,
      borderRadius: 0.08,
    },
    seriesName: 'ORISSA',
    name: 'POWDER BLUE',
    description: 'A hand-painted marquetry style geometric',
    productId: 'Ori-Pow',
  },
  {
    id: '3',
    type: 'price',
    position: { x: -14.474038983908779, y: 0.5438278386438793, z: 9.731793986163469 },
    face: { x: 0.0407000411915176, y: 0.9990001170678867, z: -0.018500614729174934 },
    shape: {
      width: 3,
      height: 1,
      borderRadius: 0.08,
    },
    seriesName: 'VISAGE',
    name: 'SEAFOAM',
    description: 'Bring the feeling of a Mediterranean summer escape into your bedroom with Visage',
    productId: 'Vis-Sea',
  },
  {
    id: '3-1',
    type: 'price',
    position: { x: -14.76837197888868, y: 0.7167606366503387, z: 9.239761729091631 },
    face: { x: 0.09070171504272365, y: 0.9864393103233878, z: -0.136787009386956 },
    shape: {
      width: 3,
      height: 1,
      borderRadius: 0.08,
    },
    seriesName: 'VISAGE',
    name: 'SEAFOAM',
    description: '50x50cm Cushion. Cotton slub printed',
    productId: 'Vis-Sea-Cus',
  },
  {
    id: '3-2',
    type: 'price',
    position: { x: -15.062443019764576, y: 0.7478435430295678, z: 9.758399653966446 },
    face: { x: 0.5006996990065172, y: 0.7277621439009523, z: -0.4686812064927211 },
    shape: {
      width: 3,
      height: 1,
      borderRadius: 0.08,
    },
    seriesName: 'SLOAN',
    name: 'TOAST',
    description: '50x50cm Cushion. Cotton with fringing',
    productId: 'Slo-Toa-Cus',
  },
  {
    id: '4',
    type: 'price',
    position: { x: -11.722490167091307, y: 0.5736128888192957, z: 12.057741631365039 },
    face: { x: -0.033404440020705244, y: 0.997363472573796, z: 0.06442241040540418 },
    shape: {
      width: 4,
      height: 1,
      borderRadius: 0.08,
    },
    seriesName: 'GEENA',
    name: 'SKYLIGHT',
    description: 'Geena skylight is your contemporary check with a twist',
    productId: 'Gee-Sky',
  },
  {
    id: '4-1',
    type: 'price',
    position: { x: -11.361559654699905, y: 0.7746945085948223, z: 12.938060384271278 },
    face: { x: -0.07294317781703553, y: 0.9697058233856604, z: -0.23313066915764405 },
    shape: {
      width: 4,
      height: 1,
      borderRadius: 0.08,
    },
    seriesName: 'ADESSA',
    name: 'POWDER BLUE',
    description: '50x50cm Cushion. Cotton. Basket Weave',
    productId: 'Ade-Pow-Cus',
  },
  {
    id: '5',
    type: 'price',
    position: { x: -13.486500880279735, y: 0.5739274319645722, z: 11.891228635030643 },
    face: { x: -0.013464147170861562, y: 0.9999012908425461, z: 0.004015633495661412 },
    shape: {
      width: 4,
      height: 1,
      borderRadius: 0.08,
    },
    seriesName: 'MILA',
    name: 'SALTWATER',
    description: 'Welcome our newest plain dye design Mila',
    productId: 'Mil-Sal',
  },
  {
    id: '5-1',
    type: 'price',
    position: { x: -13.950772929866817, y: 0.7609960525066617, z: 12.265951673021434 },
    face: { x: 0, y: 0.8221945988720243, z: -0.5692065017071317 },
    shape: {
      width: 4,
      height: 1,
      borderRadius: 0.08,
    },
    seriesName: 'ALIA',
    name: 'CLOUD',
    description: '40x60cm Cushion. Cotton',
    productId: 'Ali-Clo-Cus',
  },
  {
    id: '6',
    type: 'price',
    position: { x: -10.210553417575214, y: 0.5764537453651428, z: 10.880122252931393 },
    face: { x: 0, y: 1, z: 0 },
    shape: {
      width: 4,
      height: 1,
      borderRadius: 0.08,
    },
    seriesName: 'ELOISE',
    name: 'BLUE',
    description: 'A delicate ditsy floral printed on the softest micro gauze cotton ground',
    productId: 'Elo-Blu',
  },
  {
    id: '6-1',
    type: 'price',
    position: { x: -9.94914572352084, y: 0.7479051235245376, z: 11.192210245694167 },
    face: { x: -0.26832715605796076, y: 0.961929056454916, z: -0.051894389577312156 },
    shape: {
      width: 4,
      height: 1,
      borderRadius: 0.08,
    },
    seriesName: 'ELOISE',
    name: 'BLUE',
    description: '48x48cm Cushion. Cotton layered gauze with raw edge trim',
    productId: 'Elo-Blu-Cus-48',
  },
  {
    id: '6-2',
    type: 'price',
    position: { x: -9.412241790780158, y: 0.8034457462308275, z: 10.696502188167246 },
    face: { x: -0.5469677086845541, y: 0.8365351139275132, z: 0.03217652595684112 },
    shape: {
      width: 4,
      height: 1,
      borderRadius: 0.08,
    },
    seriesName: 'ELOISE',
    name: 'BLUE',
    description: '45x45cm Cushion. Cotton with loop tufting & tassels',
    productId: 'Elo-Blu-Cus-45',
  },
  {
    id: '7-1',
    type: 'price',
    position: { x: -10.158236675316193, y: 0.7229042463511044, z: 6.785953306253486 },
    face: { x: 0.05454011593145088, y: 0.5891214139693606, z: 0.8062017956795465 },
    shape: {
      width: 4,
      height: 1,
      borderRadius: 0.08,
    },
    seriesName: 'ADESSA',
    name: 'VANILLA',
    description: '50x50cm Cushion. Cotton. Basket Weave',
    productId: 'Ade-Van-Cus',
  },
  {
    id: '7-2',
    type: 'price',
    position: { x: -9.96918090843214, y: 0.6682044541736788, z: 7.5753765437780585 },
    face: { x: 0.06910693040625128, y: 0.6265714140990521, z: 0.7762940777847911 },
    shape: {
      width: 4,
      height: 1,
      borderRadius: 0.08,
    },
    seriesName: 'LOFT',
    name: 'SALTWATER',
    description: '48x48cm Cushion. Front: Cotton Velvet, Reverse: Cotton/Linen',
    productId: 'Lof-Sal-Cus',
  },
  {
    id: '9',
    type: 'price',
    position: { x: -9.797885617055556, y: 0.6118100277721346, z: 3.7132400576315967 },
    face: { x: -0.04740290466748004, y: 0.9941046175833439, z: -0.0975139678638882 },
    shape: {
      width: 4,
      height: 1,
      borderRadius: 0.08,
    },
    seriesName: 'SPLASH',
    name: 'MULTI',
    description: 'Bring the joy of summer inside this season with Splash',
    productId: 'Spl-Mul',
  },
  {
    id: '9-1',
    type: 'price',
    position: { x: -10.154545427535302, y: 0.7526382339382632, z: 2.8519300831351866 },
    face: { x: -0.3229164690525014, y: 0.6287279438688542, z: 0.7074080340320624 },
    shape: {
      width: 4,
      height: 1,
      borderRadius: 0.08,
    },
    seriesName: 'TIDE',
    name: 'PINK CLAY',
    description: '40x60cm Cushion. Cotton with tufting',
    productId: 'Tid-Pin-Cus',
  },
  {
    id: '10',
    type: 'price',
    position: { x: -9.539893876646255, y: 0.8040765721672665, z: 2.462065623481315 },
    face: { x: -0.1880815113365129, y: 0.7772421312840858, z: 0.6004331889980309 },
    shape: {
      width: 4,
      height: 1,
      borderRadius: 0.08,
    },
    seriesName: 'CALDER',
    name: 'OATMEAL',
    description: '48x48cm Cushion. Cotton with tufting',
    productId: 'Cal-Oat-Cus',
  },
  {
    id: '11',
    type: 'price',
    position: { x: -5.579498290859956, y: 0.5396633864484812, z: 9.87201689598345 },
    face: { x: -0.009041326782874845, y: 0.9998730674439305, z: 0.01311881893580575 },
    shape: {
      width: 4,
      height: 1,
      borderRadius: 0.08,
    },
    seriesName: 'GEENA',
    name: 'GREEN APPLE',
    description: 'Geena green apple is your contemporary check with a twist',
    productId: 'Gee-Gre',
  },
  {
    id: '12',
    type: 'price',
    position: { x: -11.90786426679373, y: 0.5868903855946855, z: 3.6137249369802897 },
    face: { x: 0.00988419243654458, y: 0.9993899595671463, z: 0.033496439456382664 },
    shape: {
      width: 4,
      height: 1,
      borderRadius: 0.08,
    },
    seriesName: 'FERRARA',
    name: 'SAND',
    description: 'A chunky seersucker woven check',
    productId: 'Fer-San',
  },
  {
    id: '12-1',
    type: 'price',
    position: { x: -11.809925022395936, y: 0.7670689615932881, z: 3.3027175829532878 },
    face: { x: -0.2918380152310437, y: 0.531360174050564, z: 0.7952904741658606 },
    shape: {
      width: 4,
      height: 1,
      borderRadius: 0.08,
    },
    seriesName: 'COASTAL',
    name: 'VANILLA',
    description: '50x50cm Cushion. Cotton with fringing',
    productId: 'Coa-Van-Cus',
  },
  {
    id: '13',
    type: 'price',
    position: { x: -14.234126134443068, y: 0.633331056115327, z: 3.662959309340419 },
    face: { x: -0, y: 0.9998151377722793, z: 0.019227331624497482 },
    shape: {
      width: 4,
      height: 1,
      borderRadius: 0.08,
    },
    seriesName: 'MARISOL',
    name: 'GUAVA',
    description: 'This tropical delight is simply the essence of summer',
    productId: 'Mar-Gua',
  },
  {
    id: '13-1',
    type: 'price',
    position: { x: -14.245589303280031, y: 0.7993798920564543, z: 3.39476529800639 },
    face: { x: 0.09891407192626764, y: 0.9054456142929592, z: 0.4127762661934563 },
    shape: {
      width: 4,
      height: 1,
      borderRadius: 0.08,
    },
    seriesName: 'PASSIONFLOWER',
    name: 'NECTAR',
    description: '48x48cm Cushion. Cotton slub with velvet wall',
    productId: 'Pas-Nec-Cus',
  },
  {
    id: '13-2',
    type: 'price',
    position: { x: -14.103070090633201, y: 0.7962899006983898, z: 2.571585628447177 },
    face: { x: 0.01536880394878376, y: 0.9887818536737237, z: 0.14857404117388187 },
    shape: {
      width: 4,
      height: 1,
      borderRadius: 0.08,
    },
    seriesName: 'MARISOL',
    name: 'GUAVA',
    description: '45x45cm Cushion. Cotton slub with ric rac borders',
    productId: 'Mar-Gua-Cus',
  },
  {
    id: '14',
    type: 'price',
    position: { x: -18.48785766274509, y: 0.6134088039398194, z: 4.881043292762756 },
    face: { x: 0, y: 1, z: 0 },
    shape: {
      width: 4,
      height: 1,
      borderRadius: 0.08,
    },
    seriesName: 'FERRARA',
    name: 'GREEN APPLE',
    description: 'A chunky seersucker woven check, in a fun minty green cotton',
    productId: 'Fer-Gre',
  },
  {
    id: '14-1',
    type: 'price',
    position: { x: -19.074045818865468, y: 0.8041541168753558, z: 4.377600306452766 },
    face: { x: 0.580585671365729, y: 0.813211059255682, z: 0.04010051507222129 },
    shape: {
      width: 4,
      height: 1,
      borderRadius: 0.08,
    },
    seriesName: 'TIDE',
    name: 'PEACH',
    description: '40x60cm Cushion. Cotton with tufting',
    productId: 'Tid-Pea-Cus',
  },
  {
    id: '14-2',
    type: 'price',
    position: { x: -19.833983190135534, y: 0.8395936152767869, z: 4.6050292667588195 },
    face: { x: 0.6449521292307608, y: 0.751519752097564, z: -0.13876171376832974 },
    shape: {
      width: 4,
      height: 1,
      borderRadius: 0.08,
    },
    seriesName: 'COASTAL',
    name: 'SPEARMINT',
    description: '50x50cm Cushion. Cotton with fringing',
    productId: 'Coa-Spe-Cus',
  },
  {
    id: '17',
    type: 'price',
    position: { x: -18.48099402336771, y: 0.6254885569662231, z: 6.714232770422949 },
    face: { x: 0.00974545280320689, y: 0.9998032637827858, z: -0.017275991402794455 },
    shape: {
      width: 4,
      height: 1,
      borderRadius: 0.08,
    },
    seriesName: 'PASSIONFLOWER',
    name: 'NECTAR',
    description: 'Usher in Summer with this gorgeous print featuring a refreshing',
    productId: 'Pas-Nec',
  },
  {
    id: '17-1',
    type: 'price',
    position: { x: -18.709133750183, y: 0.7908697373310929, z: 6.420327017177645 },
    face: { x: 0.21853466894477164, y: 0.9676600018131694, z: -0.12600285457137975 },
    shape: {
      width: 4,
      height: 1,
      borderRadius: 0.08,
    },
    seriesName: 'PASSIONFLOWER',
    name: 'NECTAR',
    description: '48x48cm Cushion. Cotton slub with velvet wall',
    productId: 'Pas-Nec-Cus',
  },
  {
    id: '17-2',
    type: 'price',
    position: { x: -19.8211849250726, y: 0.8184150350954458, z: 6.677848428303767 },
    face: { x: 0.6265444919075626, y: 0.7606038166717386, z: 0.17007008474355048 },
    shape: {
      width: 4,
      height: 1,
      borderRadius: 0.08,
    },
    seriesName: 'ADESSA',
    name: 'HONEY',
    description: '50x50cm Cushion. Cotton. Basket Weave',
    productId: 'Ade-Hon-Cus',
  },
  {
    id: '20',
    type: 'price',
    position: { x: -12.613885788328977, y: 0.5872731586788567, z: 7.697979198463936 },
    face: { x: 0.0032429688924627844, y: 0.9995672924676359, z: 0.029235440507747106 },
    shape: {
      width: 4,
      height: 1,
      borderRadius: 0.08,
    },
    seriesName: 'RAQUELLE',
    name: 'VANILLA',
    description: 'Welcome our newest plain dye design Raquelle',
    productId: 'Raq-Van',
  },
  {
    id: '20-1',
    type: 'price',
    position: { x: -12.642705241295642, y: 0.779228826713732, z: 6.5960843615831815 },
    face: { x: 0.12818911303800687, y: 0.8417653687321986, z: 0.5244031038253634 },
    shape: {
      width: 4,
      height: 1,
      borderRadius: 0.08,
    },
    seriesName: 'SLOAN',
    name: 'VANILLA',
    description: '50x50cm Cushion. Cotton with fringing',
    productId: 'Slo-Van-Cus',
  },
  {
    id: '22',
    type: 'price',
    position: { x: -18.38182238689427, y: 0.6352626340742764, z: 8.98409729232037 },
    face: { x: -0.04125453821577183, y: 0.9983593577613771, z: 0.03970712589566561 },
    shape: {
      width: 4,
      height: 1,
      borderRadius: 0.08,
    },
    seriesName: 'SELENA',
    name: 'CLOUD',
    description: 'This scenery of woven cotton matelassé banana palms',
    productId: 'Sel-Clo',
  },
  {
    id: '22-1',
    type: 'price',
    position: { x: -19.407835424289317, y: 0.8109461586350252, z: 8.33600893729322 },
    face: { x: 0.5039287258735442, y: 0.8622748037351988, z: -0.05037858754362749 },
    shape: {
      width: 4,
      height: 1,
      borderRadius: 0.08,
    },
    seriesName: 'COASTAL',
    name: 'SEA SHELL',
    description: '50x50cm Cushion. Cotton with fringing',
    productId: 'Coa-Sea-Cus',
  },
  {
    id: '22-2',
    type: 'price',
    position: { x: -18.67598278812573, y: 0.7638969624602262, z: 8.45818877466895 },
    face: { x: -0.09901542625435049, y: 0.9901474404466641, z: -0.09901510763812124 },
    shape: {
      width: 4,
      height: 1,
      borderRadius: 0.08,
    },
    seriesName: 'AMAYA',
    name: 'OATMEAL',
    description: 'A first for Linen House!',
    productId: 'Ama-Oat',
  },
  {
    id: '22-3',
    type: 'price',
    position: { x: -19.3963301156612, y: 0.8640024695075214, z: 9.226261500571754 },
    face: { x: 0.41283358826629984, y: 0.7961819259334237, z: -0.4423378451094993 },
    shape: {
      width: 4,
      height: 1,
      borderRadius: 0.08,
    },
    seriesName: 'SELENA',
    name: 'CLOUD',
    description: '48x48cm Cushion. Cotton jacquard with tufting',
    productId: 'Sel-Clo-Cus',
  },
  {
    id: '26',
    type: 'price',
    position: { x: -18.286133524728683, y: 0.6207732206541112, z: 16.00001902912023 },
    face: { x: 0.059149569094824966, y: 0.9925031546867774, z: 0.1069524025568917 },
    shape: {
      width: 4,
      height: 1,
      borderRadius: 0.08,
    },
    seriesName: 'NOTO',
    name: 'PAPAYA',
    description: 'Inspired by sun drenched Mediterranean',
    productId: 'Not-Pap',
  },
  {
    id: '26-1',
    type: 'price',
    position: { x: -18.13466880055254, y: 0.7879020501830579, z: 17.20625755516273 },
    face: { x: 0.22494537784516214, y: 0.9703697109909424, z: 0.08821678398951174 },
    shape: {
      width: 4,
      height: 1,
      borderRadius: 0.08,
    },
    seriesName: 'ADESSA',
    name: 'POWDER BLUE',
    description: '50x50cm Cushion. Cotton. Basket Weave',
    productId: 'Ade-Pow-Cus',
  },
  {
    id: '28',
    type: 'price',
    position: { x: -15.95676904459948, y: 0.5509943658429928, z: 15.893078730303237 },
    face: { x: -0.01635071109976568, y: 0.9984011783202243, z: -0.05410860722028854 },
    shape: {
      width: 4,
      height: 1,
      borderRadius: 0.08,
    },
    seriesName: 'KATHERINE',
    name: 'GREEN',
    description: 'This hand-painted Aussie twist on a classic toile pattern',
    productId: 'Kat-Gre',
  },
  {
    id: '28-1',
    type: 'price',
    position: { x: -15.497932189398893, y: 0.7136040807954305, z: 16.274296655956118 },
    face: { x: 0.041311670688043485, y: 0.7864470496269914, z: -0.6162746011300184 },
    shape: {
      width: 4,
      height: 1,
      borderRadius: 0.08,
    },
    seriesName: 'KATHERINE',
    name: 'GREEN',
    description: '48x48cm Cushion. Printed cotton slub with pleat fringe',
    productId: 'Kat-Gre-Cus',
  },
  {
    id: '28-2',
    type: 'price',
    position: { x: -16.020860426539354, y: 0.7728856274787937, z: 17.27427219324106 },
    face: { x: 0.08303079031238117, y: 0.9850455031855888, z: -0.1509676936100955 },
    shape: {
      width: 4,
      height: 1,
      borderRadius: 0.08,
    },
    seriesName: 'ADESSA',
    name: 'VANILLA',
    description: '50x50cm Cushion. Cotton. Basket Weave',
    productId: 'Ade-Van-Cus',
  },
  {
    id: '31',
    type: 'price',
    position: { x: -13.62784053895076, y: 0.5376042738066257, z: 15.820226182963342 },
    face: { x: 0.022422298922705853, y: 0.9959248809076151, z: -0.087354863059669 },
    shape: {
      width: 4,
      height: 1,
      borderRadius: 0.08,
    },
    seriesName: 'ORTENSIA',
    name: 'CHAMBRAY',
    description: 'This modern floral is based on the hydrangea',
    productId: 'Ort-Cha',
  },
  {
    id: '31-1',
    type: 'price',
    position: { x: -13.85566916508822, y: 0.745133536442643, z: 16.3163928391293 },
    face: { x: 0.004535724324253851, y: 0.9671884593765179, z: -0.2540195095927322 },
    shape: {
      width: 4,
      height: 1,
      borderRadius: 0.08,
    },
    seriesName: 'ORTENSIA',
    name: 'CHAMBRAY',
    description: '48x48cm Cushion. Cotton with crewel stitch embroidery',
    productId: 'Ort-Cha-Cus',
  },
  {
    id: '33',
    type: 'price',
    position: { x: -11.188756188129567, y: 0.5635054188933102, z: 16.157993996019563 },
    face: { x: -0.0014798452900162049, y: 0.9991120616137126, z: 0.04210580002701207 },
    shape: {
      width: 4,
      height: 1,
      borderRadius: 0.08,
    },
    seriesName: 'MESSINA',
    name: 'RUST',
    description: 'This bold art deco style motifs together with a classic scarf',
    productId: 'Mes-Rus',
  },
  {
    id: '33-1',
    type: 'price',
    position: { x: -11.163266889801298, y: 0.7441793072839549, z: 16.626412683955042 },
    face: { x: -0.01268271438041319, y: 0.8817650956506287, z: -0.4715182550529541 },
    shape: {
      width: 4,
      height: 1,
      borderRadius: 0.08,
    },
    seriesName: 'ALIA',
    name: 'SEASHEL',
    description: '40x60cm Cushion. Cotton',
    productId: 'Ali-Sea-Cus',
  },
  {
    id: '35',
    type: 'price',
    position: { x: -9.257738444497253, y: 0.5888790122083574, z: 15.986334411071223 },
    face: { x: 0.024851125116600006, y: 0.9994655760282223, z: 0.021236381871990298 },
    shape: {
      width: 4,
      height: 1,
      borderRadius: 0.08,
    },
    seriesName: 'RAQUELLE',
    name: 'PINK CLAY',
    description: 'Raquelle features a honeycomb weave giving your space a relaxed vintage feel',
    productId: 'Raq-Pin',
  },
  {
    id: '37',
    type: 'price',
    position: { x: -6.9474976334783, y: 0.5602805508003071, z: 16.178030615924012 },
    face: { x: -0.007087318970263009, y: 0.9983600428344153, z: 0.05680664381459681 },
    shape: {
      width: 4,
      height: 1,
      borderRadius: 0.08,
    },
    seriesName: 'CALIFORNIA',
    name: 'SEASHELL',
    description: 'California is sure to be showstopper this season',
    productId: 'Cal-Sea',
  },
  {
    id: '38',
    type: 'price',
    position: { x: -4.323441644433658, y: 0.5860200977194331, z: 14.149722923059874 },
    face: { x: -0.1401368864949764, y: 0.989658932388552, z: 0.030608015078500073 },
    shape: {
      width: 4,
      height: 1,
      borderRadius: 0.08,
    },
    seriesName: 'CASA',
    name: 'MULTI',
    description: 'This hand painted beauty is a new take on the much loved patchwork style',
    productId: 'Cas-Mul',
  },
  {
    id: '39',
    type: 'price',
    position: { x: -7.82741340794066, y: 0.5997679617877856, z: 13.700494688288554 },
    face: { x: -0.01630502969883454, y: 0.9927647215426021, z: 0.1189636653224833 },
    shape: {
      width: 4,
      height: 1,
      borderRadius: 0.08,
    },
    seriesName: 'PRISCILLA',
    name: 'PISTACHIO',
    description: 'Modern floral features a dreamlike photographic style in earthy shades of green',
    productId: 'Pri-Pis',
  },
  {
    id: '39-1',
    type: 'price',
    position: { x: -8.223420573129491, y: 0.7505505865680395, z: 13.532871561896041 },
    face: { x: 0.12255569508276368, y: 0.8944143704868195, z: 0.4301197919992141 },
    shape: {
      width: 4,
      height: 1,
      borderRadius: 0.08,
    },
    seriesName: 'PRISCILLA',
    name: 'PISTACHIO',
    description: '48x48cm Cushion. Cotton slub with fringing',
    productId: 'Pri-Pis-Cus',
  },
  {
    id: '41',
    type: 'price',
    position: { x: -4.411942815231764, y: 0.5664586799268791, z: 15.88873798117314 },
    face: { x: 0.04184945549163512, y: 0.9990251875302111, z: -0.014046271935279275 },
    shape: {
      width: 4,
      height: 1,
      borderRadius: 0.08,
    },
    seriesName: 'HELIOS',
    name: 'VANILLA',
    description: 'A vision of the Greek Islands in all their glory',
    productId: 'Hel-Van',
  },
  {
    id: '41-1',
    type: 'price',
    position: { x: -3.8935697441749504, y: 0.7349163097251313, z: 16.284511227546922 },
    face: { x: -0.9846700160306392, y: 0.09069209507593459, z: -0.14899632015911743 },
    shape: {
      width: 4,
      height: 1,
      borderRadius: 0.08,
    },
    seriesName: 'HELIOS',
    name: 'VANILLA',
    description: '48x48cm Cushion. Cotton with terry chenille tufting & tassels',
    productId: 'Hel-Van-Cus',
  },
];

export default tags;
