import DataStorage from 'src/apis/data/DataStorage';

class TagsRepository {
  constructor() {}

  getTags = () => {
    return DataStorage.tags;
  };

  getTagById = (id) => {
    const tag = DataStorage.tags.find((x) => x.id == id);
    return tag;
  };
}

export default new TagsRepository();
